import service from '@/utils/request';

// @Tags RmUserFavorite
// @Summary 创建RmUserFavorite
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmUserFavorite true "创建RmUserFavorite"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmUserFavorite/createRmUserFavorite [post]
export var createRmUserFavorite = function createRmUserFavorite(data) {
  return service({
    url: "/rmUserFavorite/createRmUserFavorite",
    method: 'post',
    data: data
  });
};

// @Tags RmUserFavorite
// @Summary 删除RmUserFavorite
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmUserFavorite true "删除RmUserFavorite"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmUserFavorite/deleteRmUserFavorite [delete]
export var deleteRmUserFavorite = function deleteRmUserFavorite(data) {
  return service({
    url: "/rmUserFavorite/deleteRmUserFavorite",
    method: 'delete',
    data: data
  });
};

// @Tags RmUserFavorite
// @Summary 删除RmUserFavorite
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmUserFavorite"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmUserFavorite/deleteRmUserFavorite [delete]
export var deleteRmUserFavoriteByIds = function deleteRmUserFavoriteByIds(data) {
  return service({
    url: "/rmUserFavorite/deleteRmUserFavoriteByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmUserFavorite
// @Summary 更新RmUserFavorite
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmUserFavorite true "更新RmUserFavorite"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmUserFavorite/updateRmUserFavorite [put]
export var updateRmUserFavorite = function updateRmUserFavorite(data) {
  return service({
    url: "/rmUserFavorite/updateRmUserFavorite",
    method: 'put',
    data: data
  });
};

// @Tags RmUserFavorite
// @Summary 用id查询RmUserFavorite
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmUserFavorite true "用id查询RmUserFavorite"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmUserFavorite/findRmUserFavorite [get]
export var findRmUserFavorite = function findRmUserFavorite(params) {
  return service({
    url: "/rmUserFavorite/findRmUserFavorite",
    method: 'get',
    params: params
  });
};

// @Tags RmUserFavorite
// @Summary 分页获取RmUserFavorite列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmUserFavorite列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmUserFavorite/getRmUserFavoriteList [get]
export var getRmUserFavoriteList = function getRmUserFavoriteList(params) {
  return service({
    url: "/rmUserFavorite/getRmUserFavoriteList",
    method: 'get',
    params: params
  });
};
export var getRmUserFavoriteAndRmMenuList = function getRmUserFavoriteAndRmMenuList(params) {
  return service({
    url: "/rmUserFavorite/getRmUserFavoriteAndRmMenuList",
    method: 'get',
    params: params
  });
};