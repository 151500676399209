import service from '@/utils/request';
export var getCurrUserMapDealerCodes = function getCurrUserMapDealerCodes(data) {
  return service({
    url: "/tdDealer/getCurrUserMapDealerCodes",
    method: 'get',
    data: data
  });
};
export var getAllRegions = function getAllRegions() {
  return service({
    url: "/tdDealer/getAllRegions",
    method: 'get'
  });
};
export var getSubRegionsByRegion = function getSubRegionsByRegion(params) {
  return service({
    url: "/tdDealer/getSubRegionsByRegion",
    method: 'get',
    params: params
  });
};